/* =================== DETALHE PRODUTO =================== */
jQuery(document).ready(function($) {
  // Carrosel fotos
  $(".produto-detalhe-fotos-destaque").slick({
    slidesToShow: 1,
    infinite: false,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".produto-detalhe-fotos-thumbs",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: false
        }
      }
    ]
  });

  // Carrosel thumbs
  $(".produto-detalhe-fotos-thumbs").slick({
    slidesToShow: 4,
    infinite: false,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    asNavFor: ".produto-detalhe-fotos-destaque",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  });
});
